.stake-detail-modal .modal-title {
    font-weight: bold;
    color: #333;
    text-align: center;
  }
  
  .stake-detail-content {
    padding: 16px;
  }
  
  .token-image img {
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .stake-info .info-row {
    display: flex;
    align-items: center;
    padding: 8px 0;
    font-size: 16px;
  }
  
  .info-row strong {
    margin-right: 8px;
  }
  
  .info-icon {
    color: #007bff;
    margin-right: 8px;
  }
  
  .actions .ant-btn {
    min-width: 100px;
    margin: 0 8px;
  }
  
  .actions .ant-btn-danger {
    background-color: #f5222d;
    border-color: #f5222d;
    color: white;
  }
  